export const menuItems = [
  {
    /*{
    name: "about",
    label: "About Us",
  },
  {
    name: "https://uxtheme.net/product-support/",
    label: "Support",
    isExternal: true,
  },*/
  },
];
