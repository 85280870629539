import React from "react";
import Footer3 from "./Footer3";

const Footer = ({ className = "", style, ...rest }) => {
  let FooterEl = Footer3;

  if (style === "style3") {
    FooterEl = Footer3;
  }

  return (
    <>
      <FooterEl className={className} {...rest} />
    </>
  );
};

export default Footer;
